
import FIcon from '@fixit/icons/src/components/FIcon.vue'
import ChooseSalonDialog from '../../components/chooseSalonDialog.vue'
import { appLinkList } from "@/static/appLinks";

export default {
    components: { FIcon, ChooseSalonDialog },

  head(){
    return {
      title: 'Bonus',
      titleTemplate: `%s |  ${this.$store.getters["cmsConfigHomepage/getChainName"]}`,
    }
  },
    layout: "HomepageLayout",
  name: 'bonus',
  data: () => ({
    isMounted: false,
    collapsed: false,
    bannerImgRegular:"//res.cloudinary.com/odin-systemer/image/upload/w_1170,h_300,g_face,g_face,c_fill,g_center,e_sharpen:100/v1504635470/resources/Hjemmeside_Malbilder/kvittering.jpg",
    bannerImgMobile: "//res.cloudinary.com/odin-systemer/image/upload/w_500,h_250,g_face,g_face,c_fill,g_center,e_sharpen:100/v1504635470/resources/Hjemmeside_Malbilder/kvittering.jpg"
  }),
  computed: {
    chainName() {
      return this.$store.getters["cmsConfigHomepage/getChainName"]
    },
    linkList() {
      return appLinkList();
    },
    isDraft() {
      return this.$store.getters['cmsConfigHomepage/isDraftMode']
    },
    customerPrograms() {
      let customerPrograms = this.$store.getters['cmsConfigHomepage/getActiveCustomerPrograms']
      return customerPrograms != null && customerPrograms != undefined && customerPrograms.length ? customerPrograms : this.timeoutRedirect()
    }
  },
  methods: {
    normalRedirect(link) {
      window.open(link)
    },
    timeoutRedirect() {
      setTimeout( () => this.$router.push('/'), 5000);
    }
  },
  mounted() {
    this.isMounted = true;
  }
}
